<template>
  <div>
    <fw-panel title="Marcações" boxed="xs" class="m-5">
      <template #toolbar>
        <fw-button :type="isLiveMode ? 'primary' : 'xlight'" @click.native="selectLive()">
          Live
        </fw-button>
        <fw-button type="link" :disabled="loading" @click.native="selectDates">
          {{ isLiveMode ? 'Filtrar' : appliedFilter.label }}
          <fw-icon-filter class="fill-current text-gray-500 h-5 w-5" />
        </fw-button>
        <b-dropdown aria-role="list" position="is-bottom-left">
          <fw-button-dropdown
            slot="trigger"
            aria-role="listitem"
            type="xlight"
            label="Vista"
            class="font-medium text-gray-500"
          >
            <fw-icon-list-row class="h-4 w-4" /> {{ activeView == 'list' ? 'Ver em lista' : 'Ver em tabela' }}
          </fw-button-dropdown>
          <b-dropdown-item label="list" paddingless>
            <fw-button type="dropdown-item" @click.native="changeView('list')">Ver em lista </fw-button>
          </b-dropdown-item>
          <b-dropdown-item label="table" paddingless>
            <fw-button type="dropdown-item" @click.native="changeView('table')">Ver em tabela</fw-button>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <div
        v-if="!loading && activeView == 'list'"
        class="grid gap-4"
        style="grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));"
      >
        <div v-for="map in maps" :key="map.key" class="border border-gray-300 rounded-lg p-3">
          <fw-heading>{{ map.title }}</fw-heading>
          <div v-for="map_user in map_users[map.key]" :key="map_user.key">
            <fw-person :person="users[map_user.user_key]" size="xs"></fw-person>
            <div class="flex flex-wrap">
              <fw-panel-info v-if="!attendances[map_user.user_key] || !attendances[map_user.user_key].length" clean
                >Sem marcações</fw-panel-info
              >
              <div
                v-for="attendance in attendances[map_user.user_key]"
                :key="attendance.key"
                :title="`Marcação em ${attendance.record_at}`"
              >
                <fw-icon-arrow-down v-if="attendance.action == 'entry'" class="h-4 w-4 flex-shrink-0 text-primary" />
                <fw-icon-arrow-up v-else class="h-4 w-4 flex-shrink-0 text-red-600" />
              </div>
            </div>
            <hr class="dropdown-divider" />
          </div>
        </div>
      </div>

      <div v-else-if="!loading && activeView == 'table'" ref="tableParent" class="flex-1">
        <PanelManageAttendancesStickyTable
          :start-date="appliedFilter.dates[0]"
          :end-date="appliedFilter.dates[1]"
          :height="tableHeight"
          :maps="maps"
          :users="users"
          :attendances="attendances"
          :map-users="map_users"
          :table-parent-ref-name="'tableParent'"
        >
        </PanelManageAttendancesStickyTable>
      </div>
    </fw-panel>

    <ModalChooseDates ref="chooseDates" @save="applyDates" />
  </div>
</template>

<script>
import ModalChooseDates from '@/fw-modules/fw-core-vue/ui/components/modals/ModalChooseDates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import dates from '@/fw-modules/fw-core-vue/utilities/dates'
import PanelManageAttendancesStickyTable from '@/components/panels/manage/PanelManageAttendancesStickyTable'

export default {
  components: {
    ModalChooseDates,
    PanelManageAttendancesStickyTable,
  },

  data() {
    return {
      loadingExportToCSV: false,
      loading: false,
      maps: [],
      attendances: {},
      appliedFilter: {
        key: 'live',
        label: 'Live',
        dates: [new Date(), new Date()],
      },
      users: {},
      selectedDates: null,
      wsSubscription: null,
      map_users: {},
      tableHeight: null,
      activeView: 'list',
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    connectionId() {
      return this.$store.state.socket.connectionId
    },

    isLiveMode() {
      return this.appliedFilter.key == 'live'
    },
  },

  watch: {
    isLiveMode(newVal) {
      console.log('isLiveMode :>> ', newVal)
      if (newVal && !this.wsSubscription) {
        this.subscribeWs()
      } else if (!newVal && this.wsSubscription) {
        this.unsubscribeWs()
      }
    },
  },

  mounted() {
    this.getDashboardData()
    this.subscribeWs()
  },

  methods: {
    subscribeWs() {
      if (!this.wsSubscription) {
        this.wsSubscription = this.api.createAttendancesSubscription(this.updateData)
      }
      window.addEventListener('beforeunload', this.unsubscribeWs, { capture: true })
    },

    unsubscribeWs() {
      if (this.wsSubscription) {
        this.wsSubscription.destroy()
        this.wsSubscription = null
      }
    },

    updateData(data) {
      console.log('updateData :>> ', data)
      const { map, attendance, user } = data
      if (!this.maps.find(s => s.key === map.key)) {
        this.maps.push(map)
      }

      if (!this.map_users[map.key]) {
        this.map_users = { ...this.map_users, [map.key]: [{ user_key: user.key, map_key: map.key }] }
      } else if (!this.map_users[map.key].find(s => s.user_key === user.key && s.map_key === map.key)) {
        this.map_users[map.key].unshift({ user_key: user.key, map_key: map.key })
      }

      if (!this.attendances[user.key]) {
        this.attendances = Object.assign({ [user.key]: [attendance] }, this.attendances)
      } else {
        this.attendances[user.key].push(attendance)
      }

      this.users = { ...this.users, [user.key]: user }
    },

    async getDashboardData() {
      this.loading = true
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getManagerAttendances(this.parseQueryDates())
        console.log('response :>> ', response)
        this.attendances = response.attendances
        this.maps = response.maps
        this.users = response.users
        this.map_users = response.map_users
      })
      this.loading = false
    },

    selectDates() {
      this.$refs.chooseDates.open()
    },

    applyDates({ key, label, dates }) {
      this.appliedFilter = {
        key: key,
        label: label,
        dates: dates,
      }

      this.getDashboardData()
    },

    selectLive() {
      this.appliedFilter = {
        key: 'live',
        label: 'Live',
        dates: [new Date(), new Date()],
      }
      this.getDashboardData()
    },

    parseQueryDates() {
      const query = {
        start_at: null,
        end_at: null,
      }

      if (this.appliedFilter.dates?.[0])
        query.start_at = dates.build(this.appliedFilter.dates?.[0]).format('YYYY-MM-DD')
      if (this.appliedFilter.dates?.[1]) query.end_at = dates.build(this.appliedFilter.dates?.[1]).format('YYYY-MM-DD')

      return query
    },

    changeView(view) {
      this.tableHeight = null
      this.activeView = view
      this.renderTable()
    },

    renderTable() {
      console.log('renderTable', this.activeView)
      if (this.activeView === 'table') {
        this.$nextTick(() => {
          this.tableHeight = window.innerHeight - this.$refs['tableParent'].offsetTop + 'px'
        })
      }
    },
  },
}
</script>
