<template>
  <fw-modal :active.sync="active" @close="active = false">
    <fw-panel :title="$t('selectdates')">
      <div class="flex gap-2 flex-wrap">
        <fw-button
          v-for="(value, key) in ranges"
          :key="key"
          :label="value.label"
          aria-role="listitem"
          :type="key == selectedRange ? 'primary' : 'xlight'"
          @click.native="selectRange(key)"
        >
          {{ value.label }}
        </fw-button>
      </div>

      <b-datepicker v-model="dates" placeholder="Click to select..." range :disabled="selectedRange != 'custom'">
      </b-datepicker>
    </fw-panel>

    <template #footer>
      <fw-button type="link-muted" @click.native="active = false">{{ $t('cancel') }}</fw-button>
      <fw-button type="primary" @click.native="save()">{{ $t('apply') }}</fw-button>
    </template>
  </fw-modal>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'

export default {
  props: {
    selectedFilter: {
      type: String,
      default: null,
    },
    filters: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      active: false,
      dates: [],
      ranges: {},
      selectedRange: null,
    }
  },

  computed: {},

  mounted() {
    if (!this.filters) {
      const today = Dates.now()
      this.ranges = {
        today: {
          label: this.$t('today'),
          startDate: today.toDate(),
        },
        yesterday: {
          label: this.$t('yesterday'),
          startDate: today.subtract(1, 'days').toDate(),
        },
        last7Days: {
          label: this.$t('lastDays', { days: 7 }),
          startDate: today.subtract(7, 'days').toDate(),
        },
        last30Days: {
          label: this.$t('lastDays', { days: 30 }),
          startDate: today.subtract(30, 'days').toDate(),
        },
        custom: {
          label: this.$t('custom'),
          startDate: null,
        },
      }
    } else {
      this.ranges = this.filters
    }
    this.selectedRange = this.selectedFilter
  },

  methods: {
    open() {
      this.active = true
    },

    close() {
      this.active = false
    },

    selectRange(key) {
      console.log('selectRange :>> ', key)
      const range = this.ranges[key]
      if (key == 'custom') {
        this.dates = []
      } else if (range?.startDate) {
        this.dates = [range.startDate, new Date()]
      }

      this.selectedRange = key
    },

    save() {
      let label = this.ranges[this.selectedRange].label
      if (this.selectedRange === 'custom') {
        label = `${this.dates[0].toLocaleDateString('pt-PT')} - ${this.dates[1].toLocaleDateString('pt-PT')}`
      }
      this.$emit('save', { key: this.selectedRange, label, dates: this.dates })
      this.close()
    },
  },
}
</script>

<i18n lang="json">
	{
		"pt": {
			"lastDays": "Últimos {days} dias",
			"today": "Hoje"
		},
		"en": {
			"lastDays": "Last {days} days",
			"today": "Today"
		}
	}
</i18n>
